import * as React from "react";
import * as PropTypes from "prop-types";
import {graphql, StaticQuery} from "gatsby";
import Seo from "../SEO";
import Nav from "./Nav";

const Layout = ({children, seo}) => {
  const query = graphql`
query {
  strapiGlobal {
    defaultSeo {
      metaTitle,
      metaDescription,
      shareImage {
        url
      }
    }
  }
}
`;
  return (
      <StaticQuery
          query={query}
          render={(data) => (
              <>
                <Seo seo={seo}/>
                <Nav/>
                <main>{children}</main>
              </>
          )}
      />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
