import * as React from "react";
import {graphql, Link, StaticQuery} from "gatsby";
import {createTheme} from "@mui/material/styles";
import {ThemeProvider} from "@emotion/react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CategoriesMenu from "./CategoriesMenu";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";

const mdTheme = createTheme();


const Nav = () => {
  const query = graphql`
query {
  strapiGlobal {
    siteName
  },
  allStrapiCategory {
    edges {
      node {
        slug,
        name
      }
    }
  }
}
`;
  return (
      <StaticQuery
          query={query}
          render={(data) => (
              <ThemeProvider theme={mdTheme}>
                <Box sx={{display: "flex"}}>
                  <CssBaseline/>
                  <AppBar position="relative">
                    <Toolbar
                        sx={{
                          pr: "24px", // keep right padding when drawer closed
                          justifyContent: "space-between"
                        }}
                    >
                      <Link to="/blog">
                        <Typography variant="h5"
                                    color="white">
                          {data.strapiGlobal.siteName}
                        </Typography>
                      </Link>
                      <CategoriesMenu
                          categories={data.allStrapiCategory.edges}/>
                    </Toolbar>
                  </AppBar>
                </Box>
              </ThemeProvider>
          )}
      />
  );
};

export default Nav;
