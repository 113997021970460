import * as React from "react";
import ArticleCard from "./ArticleCard";
import Grid from "@mui/material/Grid";

const Articles = ({articles}) => {
  return (
      <Grid container
            justifyContent="space-evenly"
            spacing={3}>
        {articles.map((article, i) => {
          return (
              <Grid item key={`article_${article.node.slug}_${i}`}>
                <ArticleCard
                    article={article}
                    key={`article_${article.node.slug}`}
                />
              </Grid>
          );
        })}
      </Grid>
  );
};

export default Articles;
