import * as React from "react";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import {CardActionArea} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {useI18next} from "gatsby-plugin-react-i18next";

const ArticleCard = ({article}) => {
  const {navigate} = useI18next();
  const goToArticle = () => {
    navigate(`/article/${article.node.slug}`);
  };

  return (
      <Card sx={{maxWidth: 345}}>
        <CardActionArea onClick={goToArticle}>
          {article.node.cover && (
              <CardMedia>
                <GatsbyImage
                    image={getImage(article.node.cover.file)}
                    alt={`Hero image`}
                />
              </CardMedia>
          )}
          <CardContent>
            <Typography gutterBottom
                        variant="subtitle1"
                        component="p"
                        sx={{textTransform: "uppercase"}}>
              {article.node.category?.name}
            </Typography>
            <Typography gutterBottom
                        variant="h5"
                        component="p">
              {article.node.title}
            </Typography>
            <Grid container gap={2}>
              {article.node.author?.avatar && (
                  <Grid item>
                    <GatsbyImage
                        image={getImage(article.node.author.avatar.file)}
                        alt={`Picture of ${article.node.author.name}`}
                        style={{borderRadius: "50%"}}
                    />
                  </Grid>
              )}
              <Grid item sx={{alignSelf: "center"}}>
                <Typography variant="body1">
                  {article.node.author?.name}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
  );
};

export default ArticleCard;
