import * as React from "react";
import ArticleLayout from "../components/cms/ArticleLayout";
import {graphql, useStaticQuery} from "gatsby";
import Articles from "../components/cms/Articles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function Blog() {
  const query = graphql`
  query {
    strapiAbout {
      title
    }
    allStrapiArticle {
      edges {
        node {
          strapiId
          slug
          title
          category {
            name
          }
          author {
            name
            avatar {
              file {
                childImageSharp {
                  gatsbyImageData(width: 30, placeholder: BLURRED)
                }
              }
            }
          }
          cover {
            file {
              childImageSharp {
                gatsbyImageData(width: 660, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;
  const data = useStaticQuery(query);

  return (
    <ArticleLayout seo={{}}>
      <Box sx={{p: 2}}>
        <Typography variant="h2"
                    gutterBottom>
          {data.strapiAbout.title}
        </Typography>
        <Articles articles={data.allStrapiArticle.edges}/>
      </Box>
    </ArticleLayout>
  );
};
