import * as React from "react";
import {Link} from "gatsby";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";

export default function CategoriesMenu({categories}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
      <React.Fragment>
        <IconButton color="inherit" onClick={handleClick}>
          <Typography>Categories</Typography>
        </IconButton>
        <Menu
            id="categories-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{"aria-labelledby": "basic-button"}}
        >
          {categories.map((category, i) => (
              <MenuItem key={`category__${category.node.slug}`}>
                <ListItemIcon>
                  <Link to={`/category/${category.node.slug}`}>
                    {category.node.name}
                  </Link>
                </ListItemIcon>
              </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
  );
}
